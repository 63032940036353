.works {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 75vh;
    margin-top: 4rem;
    align-items: center;
}

.workRight {
    position: relative;
}

.workMainCircle {
    position: relative;
    left: 22rem;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;
}

.workMainCircle>* {
    position: absolute;
}

.workMainCircle>:nth-child(1) {
    top: -3rem;
    left: 6rem;
}

.workMainCircle>:nth-child(2) {
    left: -3rem;
    top: 5rem;
}

.workMainCircle>:nth-child(3) {
    top: 5rem;
    left: 6rem;
}

.workMainCircle>:nth-child(4) {
    left: 15rem;
    top: 5rem;
}

.workMainCircle>:nth-child(5) {
    left: 6rem;
    top: 13rem;
}

.workSecCircle {
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    background: "none";
    box-shadow: var(--smboxShadow);
    background: white;
}

.workSecCircle>img {
    transform: scale(0.6);
}

.workbackCircle {
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}

.blueCircle {
    background: #1949B8;
    left: 29rem;
    top: 0rem;
}

.yellowCircle {
    background: #F5C32C;
    left: 29rem;
    top: 7.9rem;
}

@media screen and (max-width: 480px) {
    .works {
        flex-direction: column;
        padding: 0;
        height: 55rem;
        gap: 5rem;
    }

    .workRight {
        display: flex;
        align-items: flex-start;
        justify-content: start;
        transform: scale(.7);
    }

    .workMainCircle {
        position: static;
    }

    .workbackCircle {
        left: 8rem !important;
    }

    .blueCircle {
        top: -2rem;
    }

    .yellowCircle{
        top: 6.9rem;
    }
}