.testimonialWrapper {
    padding:  0 3rem 0 3rem;;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.testiHeading>* {
    font-size: 2rem;
    font-weight: bold;
}

.testiHeading span:nth-of-type(2){
    color: var(--orange);
}

.testimonial {
    width: 30rem;
    height: 12rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

    /* Styles same as work.css */
    background: rgba(255,255,255,.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}

.testimonial>img {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
}

.testimonial>span {
    color: var(--gray);

}

/* Slider */

.testimonialWrapper .swiper {
    width: 100%;
    height: 60%;
}

.testimonialWrapper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonialWrapper .swiper-pagination-bullet-active {
    background: var(--orange);
}

@media screen and (max-width: 480px) {
    .testimonialWrapper {
        padding: 0;

    }

    .testimonial {
        height: 15rem;
        box-shadow: none;
        gap: .8rem;
    }

    .testimonial>span {
        font-size: 13px;
    }

    .testimonial>img {
        width: 4.5rem;
        height: 80px;
    }
}