.nWrapper {
    height: 10vh;
    display: flex;
    justify-content: space-between;
}

.navLeft {
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.navName {
    font-size: 1.3rem;
    font-weight: bold;
}

.navRight {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.navList {
    flex: 10;
}

.navList>ul {
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer;
}

.navList>ul>li:hover {
    color: var(--orange);
}

.navButton {
    flex: 2;

}

@media screen and (max-width: 480px) {
    .navList {
        display: none;
    }

    .navButton {
        display: none;
    }
}