.contactForm {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
}

.contactRight {
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.contactRight>form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.contactRight .user {
    width: 20rem;
    height: 2rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
}

textarea{
    height: 4rem!important;
}

@media screen and (max-width: 480px) {
    .contactForm {
        padding: 0;
        gap: 4.5rem;
        height: 45rem;
        flex-direction: column;
    }

    .contactRight .user {
        width: 16rem;
    }
}