.floatingDiv {
    justify-content: space-around;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0px 14px 0px 0px;
    height: 4.5rem;
}

.floatingDiv>img {
    transform: scale(0.4);
}

.floatingDiv>span {
    font-family: sans-serif;
    font-size: 16px;
    color: black;
}

/* @media screen and (max-width: 480px) {
    .floatingDiv>span:nth-child(2){
        
    }
} */