.services {
    display: flex;
    padding: 0 3rem 0 3rem;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
}

.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome>:nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}

.serviceButton {
    width: 8rem;
    margin-top: 1rem;
}

.cards {
    position: relative;
}

.cards>* {
    position: absolute;
}

@media screen and (max-width: 480px) {
    .services {
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 75rem;
        padding: 0;
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }

    .cards>* {
        position: static; 
    }
}