.card {
    gap: 1rem;
    width: 10rem;
    height: 13rem;
    padding: 0 26px 2rem 26px;
    display: flex;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    box-shadow: var(--boxShadow);
    border: 5px solid var(--orangeCard);
    background: rgba(255, 255, 255, .26);
    position: absolute;
}

.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 16px;
}

.card>img {
    transform: scale(.6);
    margin-bottom: -2rem;
}

.card>img:nth-child(1){
    height: 6.5rem;
}

.cardButton {
    background: white;
    box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    border: none;
    font-size: 16px;
    color: #5290fd;
}

@media screen and (max-width: 480px) {
    .card {
        width: auto;
    }
}